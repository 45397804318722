export default [
    {
        path: '/projekty',
        name: 'projekty',
    },
    {
        path: '/publikacje',
        name: 'publikacje',
    },
    {
        path: '/wydarzenia',
        name: 'wydarzenia',
    },
    {
        path: '/kontakt',
        name: 'kontakt',
    },
]